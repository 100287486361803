/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    max-height: 12.5rem;
    margin: 18.75rem auto;
  }

  /* .staticPageMarkdown #collapse-btn-event, .staticPageMarkdown #devJourneyNavb {
    display: block !important;
  } */
  /* .staticPageMarkdown #devJourneyNavb.collapseJourneyEvent{
    left: 0 !important;
  } */
}

@media (max-width: 1439px) {
  .devJourneyComponent .djTransform {
    transition: all 0.3s ease;
    width: calc(100% - 12.7rem) !important;
  }
}

@media (min-width: 1367px) {
  .toolsAndDocs .collapse-btn-event {
    display: none !important;
  }
  .toolsAndDocs #devJourneyNavb {
    display: none !important;
  }
  .eventList-comp #devJourneyNavb {
    display: none !important;
  }
  .multisite-markdown .collapse-btn-event {
    display: none !important;
  }
  .multisite-markdown #devJourneyNavb {
    display: none !important;
  }
  .dev-tours .collapse-btn-event {
    display: none !important;
  }
  .dev-tours #devJourneyNavb {
    display: none !important;
  }
  .event .collapse-btn-event {
    display: none !important;
  }
  .event #devJourneyNavb {
    display: none !important;
  }
  .devJourneyContent {
    margin-left: 10rem !important;
  }
  /* .staticPageMarkdown #collapse-btn-event, .staticPageMarkdown #devJourneyNavb,  */
  .lowResArrow,
  .lowResArrowDj,
  .takeTour-img {
    display: none !important;
  }
}

@media (max-width: 1366px) {
  /* .toolsAndDocs #devJourneyNavb {
    display: none !important;
  } */

  .devJourneyNavEvent.collapseJourneyEvent .devJourney-title {
    opacity: 1;
  }

  .toolsAndDocs #devJourneyNavbarr {
    display: none !important;
  }
  /* .takeTour {
    margin-top: 3rem;
  } */
  .max-width-blogpage {
    max-width: calc(100% - 10rem) !important;
  }
  .eventList-comp #devJourneyNavbarr {
    display: none !important;
  }
  .multisite-markdown #devJourneyNavbarr {
    display: none !important;
  }
  .dev-tours #devJourneyNavbarr {
    display: none !important;
  }
  .event #devJourneyNavbarr {
    display: none !important;
  }
  .staticPageMarkdown #devJourneyNavbarr {
    display: none !important;
  }
  /* .devJourneyContent {
    width: calc(100% - 20.625rem) !important;
    margin-left: 8rem !important;
  } */
  /* .Modal-static.ReactModal__Content.ReactModal__Content {
    left: 4.5rem;
    max-width: calc(100% - 9rem);
  } */
  .Overlay-static {
    left: 5rem !important;
    max-width: calc(100% - 10rem) !important;
  }
  .devJourneyComponent .djTransform {
    width: calc(100% - 9.7rem) !important;
  }
}

/* 1440 and above */
@media (min-width: 1440px) {
  .toolsAndDocs #collapse-btn-event {
    display: none !important;
  }
  .arrow-navHeader {
    position: relative;
    top: -0.1rem;
    width: 3.8rem;
  }
  .categoryList .btn-positn {
    left: 1.7rem;
  }
  .eventsListComponent .eventsListComponent-main #devJourneyNavb {
    top: 0.5rem !important;
    z-index: 5 !important;
  }

  .eventsListComponent .btn-positn-event {
    left: 16.2rem !important;
  }
  .home-container .devJourney-tour-posin {
    transform: translateX(780%);
  }
  .toolsandDocs-left .collapseJourneyEvent {
    top: 1.4rem;
  }
  .toolsandDocs-left .devJourneyNavEvent {
    top: 1.4rem;
  }
  /* .eventsListComponent .collapseJourneyEvent {
  } */
  /* .navHeader {
    position: fixed;
    top: 5rem;
  }  */
  /* .Modal-static.ReactModal__Content.ReactModal__Content {
    width: 73.2rem !important;
  } */
  .max-width-blogs {
    max-width: 81.5rem !important;
  }
  .max-width-tools {
    max-width: 85.62rem !important;
  }
  .toolsandDocs-right {
    width: calc(100% - 18.75rem) !important;
  }
  .categoryList {
    padding-left: 2.25rem !important;
  }
  .max-width-blogpage {
    max-width: 83.1rem !important;
  }
  .security-markdown .security-markdown-right-side {
    padding-right: 0rem !important;
  }
  .toolsandDocs-left {
    width: 18.75rem !important;
  }
  .breadcrumb-img-cont-2 {
    margin-right: 1.4rem !important;
  }
  .components ul {
    padding-left: 1.5rem !important;
  }
  .search-textt {
    /* padding-left: 1.6rem !important; */
  }
  .search-iconn {
    margin: 0 0.5rem 0 2rem !important;
  }
  .security-markdown-leftnav {
    width: 19.5rem !important;
  }
  .dnt-right-content{
    margin-left: 19.5rem !important;
  }
  .ul-classes {
    /* padding: 2rem 0.7rem 2rem 0rem !important; */
  }
  .flex.multidocCont .ul-classes {
    padding: 0rem 0.7rem 2rem 0.5rem !important;
  }
  .flex.multidocCont .security-markdown-leftnav .searchContent > .ul-classes {
    padding-left: 1rem !important;
    padding-top: 0 !important;
  }
  .flex.multidocCont .search-textt {
    /* padding-left: 1.2rem !important; */
  }
  .journey .txt-content {
    margin-right: 30rem !important;
  }
  .devJourneyContent {
    margin-left: 8.2rem !important;
  }
  .multidocCont .ul-classes.multidic > ul {
    padding-left: 0;
  }
  /* .Modal-static.ReactModal__Content.ReactModal__Content { */
  /* left: -4rem!important; */
  /* } */
}

@media (min-width: 1640px) {
  .devJourneyContent {
    max-width: 81.5rem !important;
    margin-left: 1.5rem !important;
    width: calc(50% + 26rem) !important;
  }
}

@media (min-width: 1800px) {
  .devJourneyContent {
    margin-left: -2.4rem !important;
  }
}

@media (min-width: 1270px) and (max-width: 1366px) {
  .categoryList .collapse-btn-event {
    left: -5.3rem !important;
  }
  .eventsListComponent-header-heading,
  .eventsListComponent-main {
    width: 70rem !important;
  }
  .devJourney-bg .max-width-devjourney {
    max-width: 74rem !important;
  }
  .reference-links a {
    width: 24rem !important;
  }
  .journey .txt-content {
    margin-right: 28rem !important;
  }
  .card-cont-box {
    width: 23rem !important;
  }
  .upcoming-features-div {
    width: 23rem !important;
  }
  .devJourneyScreens {
    min-width: 60rem !important;
  }
  .devJourneyContent {
    margin-left: 12.5rem !important;
    width: calc(100vw - 15rem) !important;
  }
  .devJourney-bg .devJourney .devJourneyContent.expand {
    width: 94% !important;
    margin-left: 2.4rem !important;
  }
  .Modal-static.ReactModal__Content.ReactModal__Content {
    left: 1rem !important;
  }
}

@media (min-width: 1300px) and (max-width: 1366px) {
  .devJourneyContent {
    margin-left: 10rem !important;
  }
  .devJourney-bg .devJourney .devJourneyContent.expand {
    margin-left: -0.2rem !important;
}
}

/* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

@media (min-width: 1024px) and (max-width: 1279px) {
  /* .home-header-bg-image .nav-sticky {
    padding: 0.5em 3rem !important;
  } */
  /* .categoryList .btn-positn {
    left: 1.7rem;
  } */
  /* .eventsListComponent .collapse-btn-event {
    left: 5.1% !important;
  } */
  .devJourneyContent {
    right: 3.5rem !important;
    width: calc(100% - 18.7rem) !important;
  }
  .eventsListComponent .eventsListComponent-main #devJourneyNavb {
    top: 0.5rem !important;
  }
  .eventsListComponent .btn-positn-event {
    left: 16.2rem !important;
  }
  .toolsandDocs-left .collapseJourneyEvent {
    top: 1rem !important;
  }
  .toolsNDoc .devJourneyNavEvent {
    top: 1rem !important;
  }
  /* .categoryList .btn-positn {
    left: 11.1rem !important;
  } */
  .toolsNDoc .devJourneyNavbr .collapse-btn {
    display: none !important;
  }
  /* .categoryList .btn-positn {
    right: 0.6rem !important;
  } */
  /* .Modal-static.ReactModal__Content.ReactModal__Content {
    width: calc(100vw - 6rem) !important;
    left: 2.8rem !important;
  } */

  .main-section-home {
    padding: 5rem 5rem !important;
  }
  .eventsListComponent-main {
    width: 59rem !important;
  }
  .featured-events-div {
    margin-bottom: 1.5rem;
  }
  .devJourneyLandingLowerCard {
    width: 14rem !important;
    height: 10.5rem !important;
  }
  .journey .txt-content {
    margin-right: 7rem !important;
  }
  .footer .content .copyright {
    width: 20.8rem !important;
  }
  .home-cards-section .card-cont-box:nth-child(odd) {
    margin-right: 1rem !important;
  }
  .card-cont-box {
    height: 19rem !important;
    width: 24rem !important;
    margin-right: 1rem !important;
  }
  .card-cont {
    padding: 35px 19px !important;
  }
  .card-cont .card-view-more {
    width: 31% !important;
  }
  .position-bottom {
    bottom: 3rem !important;
  }
  .card-heading {
    font-size: 1.1rem !important;
    padding: 1rem 0 0 0.8rem !important;
  }
  .upcoming-features-div {
    width: 24rem !important;
  }
  .main-section-home {
    display: flex !important;
    flex-direction: column !important;
  }
  .card-wrapper {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: flex-start !important;
  }
  .card-wrapper span {
    margin-bottom: 1rem !important;
    margin-right: 0.5rem !important;
  }
  .devJourneyRight .devJourneyMd ol,
  .devJourneyRight .devJourneyMd p:nth-child(2) {
    width: 23rem !important;
  }
  .eventContent {
    padding: 2rem 2rem 2rem 3rem;
    justify-content: space-evenly;
  }
  .devJourneyComponent .djTransform {
    width: calc(100% - 7.7rem) !important;
  }
}

/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 767px) and (max-width: 1024px) {
  /* .home-header-bg-image .nav-sticky {
    padding: 0.5em 0.8em !important;
  } */
  .toolsandDocs .devJourneyNavEvent {
    top: 1rem !important;
  }
  /* .toolsandDocs .categoryList .collapse-btn-event {
    transform: translate(492%) !important;
  } */
  /* .Modal-static.ReactModal__Content.ReactModal__Content {
    width: 90vw !important;
    left: 3rem!important;
  } */

  .max-width-blogpage {
    max-width: calc(100% - 6rem) !important;
  }

  .devJourneyContent {
    right: 1rem !important;
    width: calc(100% - 16.7rem) !important;
  }

  .Overlay-static {
    max-width: 100% !important;
    width: calc(100% - 6.5rem) !important;
    left: 4.5rem !important;
  }
  .home-container .flex-wrap {
    flex-wrap: wrap !important;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .security-markdown .security-markdown-right-side {
    font-size: 1rem !important;
  }
  .main-section-home {
    display: flex !important;
    padding: 5rem 2rem !important;
    flex-direction: column !important;
  }
  .security-markdown .security-markdown-right-side {
    width: calc(100% - 17rem) !important;
  }
  .upcoming-features-div {
    width: 20rem !important;
  }
  .hdr-nv,
  .header-navv {
    padding: 0.5rem 2.2rem !important;
  }
  .homepage_about {
    padding: 5rem 3rem !important;
  }
  .security-markdown .gatsby-resp-image-wrapper {
    width: 100% !important;
    margin: 0 !important;
  }
  .security-markdown .gatsby-resp-image-wrapper {
    margin: 0 !important;
  }
  .api-logo-image {
    width: 20rem !important;
  }
  .security-markdown .security-markdown-leftnav {
    width: 17rem !important;
  }
  .dnt-right-content {
    margin-left: 17rem !important;
  }
  .ul-classes {
    padding: 2rem 0rem !important;
  }
  .ul-classes {
    padding: 2rem 1rem !important;
  }
  .multidocCont .ul-classes {
    padding: 0 !important;
  }
  .components ul {
    padding-left: 1.5rem !important;
  }
  .search-textt {
    padding-left: 1rem !important;
  }
  .paddingRight-45 {
    padding-right: 0 !important;
    font-size: 0.9rem !important;
  }
  .toolsandDocs-left {
    width: 14rem !important;
  }
  .categoryList {
    padding-left: 3rem !important;
  }
  .breadcrumb-img-cont-2 {
    margin-right: -4rem !important;
    position: relative;
    top: 0.6rem;
  }
  .nav-block {
    width: calc(100vw - 18.5rem) !important;
  }
  .navigation-block {
    padding: 0 1.5rem !important;
  }
  .card-wrapper {
    display: flex !important;
    flex-wrap: wrap;
  }
  .card-container {
    width: 11.5rem !important;
    margin: 0 1rem 1rem 0 !important;
  }
  .eventContent .eventLeftContent {
    width: 100% !important;
    padding-right: 0 !important;
  }
  .eventContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .main-section-home .carousal-group {
    display: none;
  }
  .navHeader {
    top: 3.95rem !important;
  }
  .rightSideCarousel-Slider {
    width: 15rem !important;
  }
  .eventsListComponent-main {
    width: 43rem !important;
  }
  .featured-events-div {
    margin-bottom: 1.5rem;
  }
  .devJourneyLandingLowerCard {
    width: 10rem !important;
    height: 8.5rem !important;
  }
  .devJourneyLandingLowerCard-title {
    font-size: 0.9rem !important;
    bottom: 1rem !important;
  }
  .journey .txt-content {
    margin-right: -3rem !important;
  }
  .home-cards-section .card-cont-box:nth-child(odd) {
    margin-right: 1rem !important;
  }
  .card-cont-box {
    height: 19rem !important;
    width: 20rem !important;
    margin-right: 1rem !important;
  }
  .card-heading {
    font-size: 1.1rem !important;
  }
  .card-cont {
    padding: 35px 19px !important;
  }
  .card-cont .card-view-more {
    width: 31% !important;
  }
  .position-bottom {
    bottom: 3rem !important;
  }
  .event-box {
    margin: 0 !important;
  }
  .tempdiv {
    padding: 1rem 1rem !important;
  }
}

/* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .hdr-nv,
  .header-navv {
    padding: 0.5rem 2rem !important;
  }
  .homepage_about {
    padding: 5rem 3rem !important;
  }
  .api-logo-image {
    width: 20rem !important;
  }
  .security-markdown .security-markdown-leftnav {
    width: 16rem;
  }
  .dnt-right-content {
    margin-left: 16rem;
  }
  .components ul {
    padding-left: 1.5rem !important;
  }
  .search-textt {
    /* padding-left: 2.5rem !important; */
  }
  .paddingRight-45 {
    padding-right: 0 !important;
  }
  .gatsby-resp-image-link .gatsby-resp-image-wrapper {
    margin: 0 1.2rem 1rem 0 !important;
  }
  .toolsandDocs-left {
    width: 14rem !important;
  }
  .categoryList ul .links {
    width: 11rem !important;
  }
  .categoryList {
    padding-left: 3rem !important;
  }
  .toolsandDocs .categoryList .collapse-btn-event {
    left: -3rem;
  }
  .eventsListComponent .collapse-btn-event {
    left: 5.3%;
  }
  .breadcrumb-img-cont-2 {
    margin-right: -4rem !important;
    position: relative;
    top: 0.6rem;
  }
  .nav-block {
    width: calc(100vw - 18.5rem) !important;
  }
  .navigation-block {
    padding: 0 1.5rem !important;
  }
  .card-wrapper {
    display: flex !important;
    flex-wrap: wrap;
  }
  .card-container {
    width: 12.5rem !important;
    margin: 0 1rem 1rem 0 !important;
  }
  .eventContent .eventLeftContent {
    width: 60% !important;
    padding-right: 0 !important;
  }
  .navHeader {
    top: 3.95rem !important;
  }
  .home-container .card-group .tempdiv {
    padding: 1rem 1rem;
  }
  .eventLeftSideContent .eventTitle h2 {
    width: auto !important;
  }

  .event .eventContent {
    padding: 0rem !important;
  }
  .event .eventLeftContent {
    padding-right: 0rem !important;
    width: 20rem !important;
    padding-top: 1.5rem !important;
  }
  .eventLeftSideContent .eventTitle h2 {
    width: 100% !important;
    font-size: 1.8rem !important;
  }
  .devJourneyLandingLowerCard {
    width: 10rem !important;
    height: 8.5rem !important;
  }
  .devJourneyLandingLowerCard-title {
    font-size: 0.9rem !important;
    bottom: 1rem !important;
  }
  .devJourneyLanding {
    height: calc(100vh - -7rem) !important;
  }
  .journey .txt-content {
    margin-right: 7rem !important;
  }
  .markdown-body {
    font-size: 1rem !important;
  }
  .main-section-home .carousal-group {
    display: none;
  }
}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {
  .home-container .displayNone {
    display: none !important;
  }
  .devJourneyContent {
    right: 0 !important;
    width: calc(100% - 18.4rem) !important;
  }
  .max-width-blogpage {
    width: calc(100% - 5rem);
  }
  .nav-sticky .right-flexx {
    align-items: center;
    padding-right: 2rem;
  }
  .hdr-nv .container-fluid {
    width: 100%;
  }
  .home-header-bg {
    display: flex;
    justify-content: center;
    background-image: linear-gradient(316deg, #474e68, #474e68);
    height: 650px;
  }
  .home-header .header-blog-div {
    width: 40rem;
    height: 13rem;
  }
  .max-width {
    max-width: 100%;
  }
  .arrow-img {
    width: 0rem;
  }
  .homepage_about .api-logo-image {
    display: none;
  }
  .home-container .connect .connect_subtitle {
    width: 95%;
    font-size: 1.25rem;
    font-weight: 300;
  }
  .home-header-bg-image .hdr-nv {
    padding: 0rem 2rem;
  }

  .aboutUs-bg .homepage_about .about_left {
    flex-basis: 100%;
    flex-direction: column;
  }
  .home-header-bg .home-header-bg-image .nav-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }

  .home-header-bg .sticky-header-new {
    position: fixed;
    width: 100%;
    top: 3.9rem;
    left: 0;
  }
  .aboutUs-bg .homepage_about .about_right {
    flex-basis: 0%;
  }
  .one-stop .one-stop-list .one-stop-list-item {
    font-size: 30px !important;
    font-weight: 300;
  }
  .home-header-bg .one-stop {
    flex-wrap: wrap;
  }
  .home-header-bg .one-stop-text {
    left: 0rem !important;
    font-weight: 300;
  }
  .home-header-bg .one-stop-list-div {
    height: 3.3rem;
    /* overflow: hidden; */
  }
  .sticky-header-new .new-navHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 7rem;
    transform: scale(0.96);
  }
  .arrow-navHeader {
    display: flex;
  }

  .home-header-bg .home-header-bg-image {
    width: 100%;
  }
  /* .home-container .home-header-bg {
    height: 48.75em;
  } */
  .home-container .footer .content {
    display: flex;
    justify-content: center;
  }
  .welcome-to-tmobile {
    margin-top: 1rem !important;
    font-size: 1.4rem !important;
    font-weight: 300 !important;
  }

  .max-width-blogpage .security-markdown {
    height: 100%;
  }
  .max-width-blogpage .mobileHeight {
    height: 100vh !important;
    overflow: hidden !important;
  }
  .devJourneyLanding {
    max-width: 85rem;
    min-width: 75rem;
    height: calc(100vh - -24rem) !important;
  }
  .expand-parent .beta-cont {
    margin-left: 2.7rem;
  }
}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {
  .display-none {
    display: none;
  }
  .right-flexx {
    display: none !important;
    align-items: center;
  }
  .hdr-nv .container-fluid {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .home-header-bg {
    display: flex;
    justify-content: center;
    height: 650px;
  }
  .add-header-top .header-navigation {
    width: 100%;
    justify-content: center;
  }
  .add-header-top .forTransform {
    transform: scale(0.8) !important;
  }
  .home-container .add-header-top .sticky-navigation {
    transform: none !important;
  }
  .home-header .header-blog-div {
    width: 18.75rem;
    height: 18.475rem;
  }
  .max-width {
    max-width: 100%;
  }
  .arrow-img {
    width: 0rem;
  }
  .main-section-home .carousal-group {
    display: none;
  }

  .homepage_about .api-logo-image {
    display: none;
  }
  .home-container .connect .connect_subtitle {
    width: 95%;
    font-size: 1.25rem;
    font-weight: 300;
  }
  .home-header-bg-image .hdr-nv {
    padding: 0rem 0rem;
  }
  .aboutUs-bg .homepage_about .about_left {
    flex-basis: 100%;
  }
  .home-header-bg .home-header-bg-image .nav-sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;
    display: flex;
    align-items: center;
  }

  .home-header-bg .sticky-header-new {
    position: fixed;
    width: 130%;
    top: 3.9rem;
  }
  .aboutUs-bg .homepage_about .about_right {
    flex-basis: 0%;
  }

  .one-stop .one-stop-list .one-stop-list-item {
    font-size: 30px;
    font-weight: 300;
    text-align: center;
  }
  .home-header-bg .one-stop {
    flex-wrap: wrap;
  }
  .home-header-bg .one-stop-text {
    left: 0rem;
    font-size: 1.7rem !important ;
    font-weight: 300;
    margin-top: 0.5rem;
  }
  .home-header-bg .one-stop-list-div {
    height: 3.3rem;
    /* overflow: hidden; */
  }
  .sticky-header-new .new-navHeader {
    justify-content: flex-start !important;
    width: 7rem;
    position: relative;
    left: -2rem;
  }
  .arrow-navHeader {
    display: flex;
  }

  .home-header-bg .home-header-bg-image {
    width: 100%;
  }
  .renderList {
    width: 7.1rem;
  }
  .home-container .home-header-bg {
    height: 50rem;
  }
  .home-container .footer .content {
    display: flex;
    justify-content: center;
  }
  .main-section-home .upcoming-features-div {
    margin-left: 1.2rem;
    padding: 0 1.5rem;
  }
  .home-container .max-width {
    max-width: 100% !important;
    padding: 0rem 1.5rem !important;
  }
  .home-container .home-cards-section {
    width: 24rem;
    /* padding: 0rem 2rem; */
  }
  .home-container .card-cont-box {
    width: 22rem;
    padding: 0 2.875rem !important;
  }
  .home-header-bg .one-stop-list-div {
    height: 3.3rem;
    position: relative;
    overflow: hidden;
    left: -1rem;
  }
  .home-header-bg .one-stop-container {
    height: 6rem;
    margin: 0.8rem;
  }
  .home-header-bg .one-stop {
    font-size: 2rem;
    line-height: 2rem;
  }
  .welcome-to-tmobile {
    margin-top: -0.2rem !important;
    font-size: 1.4rem !important;
    font-weight: 300 !important;
  }
  .header-read-more {
    padding: 1em !important;
    margin: 1em !important;
  }
  .sticky-header-new .new-navHeader .new-figure-navHeader figcaption {
    display: none;
  }
  .home-header .rest-api {
    opacity: 1;
    font-weight: 500;
  }
  .home-header .header-blog-para {
    width: 300px;
    height: 128px;
    font-size: 13px;
    line-height: 1.38;
    text-align: center;
    color: #ffffff;
    font-weight: 200;
  }
  .connect {
    padding: 2rem 1.5rem !important;
  }
  .blog-main-div {
    flex-direction: column !important;
  }
  .article-title {
    font-size: 1.5rem !important;
  }
  .article-sub-title {
    font-size: 0.65rem !important;
  }
  .max-width-blog-upper {
    width: 76rem !important;
  }
  .expand-parent .beta-cont {
    margin-left: 2.7rem;
  }
  .journey .content {
    flex-wrap: wrap;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .home-container .displayNone {
    display: none !important;
  }
  .sticky-header-new .new-navHeader .new-figure-navHeader figcaption {
    max-width: 6.6rem;
    white-space: nowrap;
  }
  .nav-sticky .navbar-brand {
    margin-right: 0 !important;
    padding-left: 2rem !important;
  }
  .mobileNavClosed .security-markdown-right-side {
    width: 100% !important;
  }
  .paddingRight-45 {
    padding-right: 0 !important;
  }
  .markdown-body h1 {
    font-size: 1.5rem !important;
    font-weight: 450 !important;
  }
  .gatsby-resp-image-link .gatsby-resp-image-wrapper {
    margin: 2rem 0rem !important;
  }
  .header-navv .header-burger img {
    width: 100% !important;
    height: 100% !important;
  }
  .hdr-nv .header-burger {
    display: none;
    margin-left: 1.875rem;
    width: 1rem !important;
  }
  .hdr-nv .header-burger img {
    width: 100%;
    height: 100%;
  }
  .home-container .home-cards-section {
    padding: 0rem 2rem;
    width: 52%;
  }
  .breadcrumbb {
    width: 100%;
  }
  .home-container .header-navv {
    padding: 0.5rem 2rem;
    position: fixed;
    width: 100%;
    z-index: 3;
  }
  .home-header .api-lifecycle {
    font-weight: 500;
  }
  .home-cards-section .card-cont-box {
    width: 100%;
  }
  .container-fluid {
    padding-left: 0em !important;
    padding-right: 0em !important;
  }
  .home-cards-section .card-cont-box:nth-child(odd) {
    margin-right: 0em !important;
  }
  .security-markdown-right-side .markdown-body {
    font-size: 0.75rem;
    width: 25rem;
  }
  .home-header-bg-image .nav-sticky {
    padding: 0rem 0rem !important;
  }
  .home-container .staticPage .markdown-body {
    font-size: 0.75rem !important;
  }
  .max-width-blogpage .mobileHeight {
    height: 100vh !important;
    overflow: hidden !important;
  }
  /* .home-container .home-header .header-navigation.sticky-navigation {
    top: 3.65 !important;
  }
  .header-navigation.sticky-navigation .nav-caption {
    display: none !important;
  }
  .sticky-navigation .renderList {
    width: 6rem !important;
  } */
  .home-container .navHeader {
    top: 4.95rem !important;
  }
  .markdown-body table {
    overflow: unset !important;
  }
  /* .header-navigation.sticky-navigation {
    padding: 0rem !important;
  }
  .home-header .sticky-navigation .arrow-img {
    left: -4.2em !important;
  }
  .home-header .sticky-navigation > a:first-child {
    margin-left: 4rem;
  } */
  .main-section-home .card-cont-box .card-para p {
    font-size: 0.75rem;
  }
  .home-container .about_subtitle {
    font-size: 2.25rem;
    font-weight: 300;
  }
  .home-container .card-group .tempdiv {
    padding: 1rem 1rem;
  }
  .home-container .card-group .textdiv {
    padding: 0rem 1rem;
  }
  .home-container .card-group .space-navbar {
    width: 0rem;
  }
  .home-container .nav-block {
    height: 2.813rem !important;
  }
  .staticPage .markdown-body {
    padding: 1.2rem 2rem !important;
  }
  /* .max-width-blogpage .mobileNavClosed .security-markdown-right-side {
    width: 100% !important ;
    transition: 1s;
  }
  .security-markdown {
    position: relative;
  }
  .max-width-blogpage .mobileNav .security-markdown-leftnav {
    width: 23rem;
    overflow: hidden;
    height: 100.5vh;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .max-width-blogpage .mobileNavOpen .security-markdown-leftnav {
    transform: translateX(0%);
    transition: transform 0.5s ease-in;
    /* height: auto; */
  /* overflow: scroll; *
  }
  .max-width-blogpage .mobileNavClosed .security-markdown-leftnav {
    /* width: 0em !important ; */
  /* overflow: hidden; *
    transform: translateX(-100%);
    transition: transform 0.5s ease-out;
  }
  .max-width-blogpage .mobileNavOpen .security-markdown-right-side {
    width: 100% !important ;
  } */
  .max-width-blogpage .security-markdown {
    height: 100%;
  }
  /* .home-header .header-navigation {
    position: relative !important;
    left: 3rem !important;
  } */
  .header-navigation.sticky-navigation .renderList:nth-child(3) figcaption {
    width: 5.5rem;
  }
  .card-cont .card-view-more {
    width: 30% !important;
  }
  .tempdiv {
    padding: 1rem 1rem !important;
  }
}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .sticky-header-new .new-navHeader .new-figure-navHeader figcaption {
    max-width: 6.6rem;
    white-space: nowrap;
  }
  .max-width-blogpage .mobileNavClosed .security-markdown-right-side {
    width: 100% !important ;
    transition: 1s;
  }
  .security-markdown {
    position: relative;
  }
  .max-width-blogpage .mobileNav .security-markdown-leftnav {
    width: 100%;
    overflow: hidden;
    height: 100vh;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .max-width-blogpage .mobileNavOpen .security-markdown-leftnav {
    transform: translateX(0%);
    transition: transform 0.5s ease-in;
    /* height: auto; */
    overflow: scroll;
  }
  .max-width-blogpage .mobileNavClosed .security-markdown-leftnav {
    /* width: 0em !important ; */
    /* overflow: hidden; */
    transform: translateX(-100%);
    transition: transform 0.5s ease-out;
  }
  .max-width-blogpage .security-markdown {
    height: 100%;
  }
  .max-width-blogpage .mobileNavOpen .security-markdown-right-side {
    width: 100% !important ;
  }

  .expand-parent .right-flexx {
    display: none;
  }
  .navbar-brand {
    margin-right: 0 !important;
  }
  /* .home-container .expand-parent .logo-new {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 9.188em !important;
    margin-right: 2.9em; 
    width: 85%;
  } */
  .mobileNavClosed .security-markdown-right-side {
    width: 100% !important;
  }
  .breadcrumb-img-cont {
    display: none;
    /* margin-right: 0rem !important;
    position: relative;
    top: -0.3rem; */
  }
  .paddingRight-45 {
    padding-right: 0 !important;
  }
  .markdown-body h1 {
    font-size: 1.5rem !important;
    font-weight: 450 !important;
  }
  .gatsby-resp-image-link .gatsby-resp-image-wrapper {
    margin: 2em 0em !important;
  }
  .navHeader {
    position: relative;
    top: -0.05rem;
  }
  .navHeader .renderList-navHeader .figure-navHeader figcaption {
    display: none;
  }
  .navHeader .renderList-navHeader .figure-navHeader {
    padding: 0.8rem 0.4rem 0rem 0.4rem !important;
  }
  .toolsandDocs-left {
    display: none !important;
  }
  .toolsandDocs-right {
    width: 100% !important;
  }
  .navigation-block {
    padding: 0 0.5rem;
  }
  .card-wrapper {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: center;
  }
  .card-container {
    margin: 0.5rem auto;
  }
  .breadcrumb-img-cont-2 {
    position: relative;
    top: 0.5rem;
    margin-right: 0 !important;
  }
  .hdr-nv .navbar-header {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-right: 0 !important;
  }
  .header-navv .navbar-header {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-right: 0;
  }
  .upcoming-features-div .card-view-more {
    width: 60% !important;
  }
  .card-cont .card-view-more {
    width: 35% !important;
  }
  .navbar-header .navbar-brand {
    /* transform: scale(0.8) !important; */
    width: 9.219rem;
  }
  .navbar-brand .logo-new {
    width: 100%;
    height: 100%;
  }
  .nav-sticky .navbar-brand {
    width: 9.219rem;
  }
  .nav-sticky .navbar-brand .logo-new {
    width: 100%;
    height: 100%;
  }
  .home-header .capitalize {
    font-size: 0.625rem;
  }
  .home-header.header-blog-icon-img {
    width: 3rem;
    height: 1rem;
  }
  .homepage_about {
    padding: 4.2rem 2rem !important;
  }
  .card-heading {
    font-size: 1.438rem !important;
    overflow: inherit !important;
    margin-bottom: 1rem !important;
  }
  .header-navv .header-burger {
    display: block;
    margin-left: 0rem;
    width: 1rem !important;
  }
  .header-navv .header-burger img {
    width: 100%;
    height: 100%;
  }
  .hdr-nv .header-burger {
    display: none;
    margin-left: 1.875rem;
    width: 1rem !important;
  }
  .hdr-nv .header-burger img {
    width: 100%;
    height: 100%;
  }
  .nav-sticky .navbar-header {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-right: 0;
  }
  .breadcrumbb {
    width: 100%;
  }
  .home-container .header-navv {
    padding: 0.5rem 0rem;
    position: fixed;
    width: 100%;
    z-index: 3;
  }
  .home-header .api-lifecycle {
    font-weight: 500;
  }
  .home-cards-section .card-cont-box {
    width: 100%;
  }
  .container-fluid {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  .home-cards-section .card-cont-box:nth-child(odd) {
    margin-right: 0rem !important;
  }
  .security-markdown-right-side .markdown-body {
    font-size: 0.85rem;
  }
  .home-header-bg-image .nav-sticky {
    padding: 0rem 0rem !important;
  }
  .home-container .staticPage .markdown-body {
    font-size: 0.75rem !important;
  }
  .max-width-blogpage .mobileHeight {
    height: 100vh !important;
    overflow: hidden !important;
  }
  .home-container .home-header .header-navigation.sticky-navigation {
    top: 3.65 !important;
  }
  .header-navigation.sticky-navigation .nav-caption {
    display: none !important;
  }
  .sticky-navigation .renderList {
    width: 6rem !important;
  }
  .home-container .navHeader {
    top: 3.95rem !important;
  }
  .markdown-body table {
    overflow: unset !important;
  }
  .header-navigation.sticky-navigation {
    padding: 0rem !important;
  }
  .home-header .sticky-navigation .arrow-img {
    left: -4.2em !important;
  }
  .home-header .sticky-navigation > a:first-child {
    margin-left: 4rem;
  }
  .main-section-home .card-cont-box .card-para p {
    font-size: 12px;
  }
  .home-container .about_subtitle {
    font-size: 2.25rem;
    font-weight: 300;
  }
  .home-container .card-group .tempdiv {
    padding: 1rem 1rem;
  }
  .home-container .card-group .textdiv {
    padding: 0rem 1rem;
  }
  .home-container .card-group .space-navbar {
    width: 0rem;
  }
  .home-container .nav-block {
    height: 2.813rem !important;
  }
  .staticPage .markdown-body {
    padding: 1.2rem 2rem !important;
  }
  .ReactModal__Body--open .ReactModalPortal .ReactModal__Content {
    position: relative;
    top: 6rem;
    left: 0px;
    right: 0px;
    width: 20rem;
    margin: 0 auto;
  }
  .ReactModalPortal .Overlay {
    top: 4rem !important;
  }
  .takeTour {
    top: 4.2rem !important;
  }
  .introjs-tooltipReferenceLayer {
    left: -1rem !important;
  }
  .sidebar-search {
    justify-content: space-between !important;
  }
  .search-textt {
    padding-left: 1.75rem !important;
  }
  .home-container .components ul {
    padding-left: 1.5rem !important;
  }
  .search-iconn {
    margin: 0 1.875rem !important;
  }
  .home-container .featured-events-box {
    width: 20.5rem !important;
  }
  .home-container .featured-events-div {
    margin-right: 0rem !important;
    margin-bottom: 1.5rem !important;
  }
  .home-container .featured-events-section {
    justify-content: center;
  }
  .uevents-details-div {
    margin-left: 1rem !important;
  }
  .home-container .uevent-detail {
    font-size: 0.7rem;
  }
  .uevent-location {
    font-size: 0.75rem !important;
    margin-right: 0.4rem !important;
  }
  .home-container .upcoming-events-div {
    width: 22rem;
  }
  .home-container .upcoming-events-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .home-container .heading-border-bottom {
    width: 20rem !important;
  }
  .upcoming-events-div:hover {
    background-color: #f9f9f9;
  }
  .event .eventContent {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 0rem !important;
  }
  .event .eventLeftContent {
    padding-right: 0rem !important;
    width: 20rem !important;
    padding-top: 1.5rem !important;
  }
  .eventLeftSideContent .eventTitle h2 {
    width: 100% !important;
    font-size: 1.8rem !important;
  }
  .header-navv {
    padding: 0rem !important;
  }
  .upcomingEvents-table-headings {
    margin: 1rem 0rem !important;
  }
  .journey .heading {
    font-size: 1.4rem !important;
    font-weight: 400 !important;
  }
  .introjs-tooltip {
    left: 4rem !important;
  }
  /* .journey .para {
    width: 100% !important;
  } */
  .journey .para {
    width: 20.75rem !important;
  }
  .txt-content {
    margin-right: 0 !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .devJourneyLandingArticle {
    justify-content: space-evenly !important;
  }
  .journey {
    height: 10rem !important;
  }
  .journey .btnn {
    width: 12rem !important;
    height: 2rem !important;
    margin-top: 0rem !important;
  }
  .bg-img-rgt {
    right: 0.5rem !important;
  }
  .bg-img-lft {
    left: 0.5rem !important;
  }
  .devJourneyLandingUpperHeading-small {
    font-size: 1.3rem !important;
    padding-top: 0.2rem !important;
  }
  .devJourneyLandingUpperHeading-big {
    font-size: 2.3rem !important;
  }
  .devJourneyLandingUpperContent {
    width: 21.25rem !important;
    padding: 0 !important;
  }
  .devJourneyLandingLowerHeading {
    font-size: 1.2rem !important;
    width: 14rem !important;
    padding: 0rem 0 0.5rem !important;
  }
  .devJourneyLandingLowerCardSection {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
  }
  .devJourneyLandingLowerCard {
    width: 8.5rem !important;
    height: 7rem !important;
  }
  .devJourneyLandingLowerCard-icon {
    width: 2rem !important;
    height: 2.5rem !important;
    margin-bottom: 2.2rem !important;
  }
  .devJourneyLandingLowerCard-title {
    font-size: 1rem !important;
    font-weight: 500 !important;
    bottom: 0.8rem !important;
  }
  .markdown-body table th,
  .markdown-body table td {
    padding: 2px 1.6px !important;
  }
  .journey .content {
    flex-wrap: wrap;
  }
}

/*Media Queries only for Internet explorer 9 and above for all resolutions */
@media screen and (min-width: 0\0) {
  .header-blog-div-content {
    top: 0;
    left: 0;
  }
  .journey .max-width {
    margin: 0 auto;
  }
  .journey .content {
    justify-content: space-around;
  }
  .card-para {
    max-width: 21rem;
  }
  .position-bottom {
    left: 10rem;
  }
  .sticky-navigation {
    left: 0;
  }
  .sticky-navigation .renderList {
    position: relative;
    top: 0rem;
  }
  .devJourneyLandingLowerCard-title {
    width: 100%;
    right: 0rem;
  }
  .home-container .template-div {
    width: 50rem !important;
  }
  .home-container .space-navbar {
    width: 370px !important;
  }
  .home-container .nav-block {
    width: 50rem !important;
  }
  .devJourneyNavbr {
    background-image: linear-gradient(
      to top,
      rgba(156, 57, 104, 0.7),
      rgb(44, 47, 76)
    );
  }
  .devJourney-bg .subStageNumber {
    left: -0.5rem !important;
  }
  .devJourney-bg .completed {
    position: relative;
    top: -0.2rem !important;
    left: -0.5rem !important;
  }
  .devJourney-bg .subStages {
    margin: 0.6rem 0.5rem !important;
  }
  .devJourney-bg .subStageName {
    position: relative;
    left: 0.6rem !important;
    top: 0.25rem !important;
  }
  .home-container .card-row {
    width: 100% !important;
  }
  .home-container .card-wrapper {
    display: flex !important;
    flex-basis: 100% !important;
    flex-wrap: wrap !important;
  }
  .toolsandDocs-right {
    width: calc(100% - 14rem) !important;
  }
  .home-container .card-cont-box:hover {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.15);
  }
  .introjs-prevbutton {
    margin-left: -7rem !important;
  }
  .home-container .event-mask {
    height: 2.7rem !important;
    z-index: 1 !important;
    left: 0.1rem;
  }
  .introjs-nextbutton {
    height: 3.1rem !important;
  }
  input[type="text"]::-ms-clear {
    color: transparent; /* This sets the cross color as red. */
    /* The cross can be hidden by setting the display attribute as "none" */
    width: 0;
  }
  .last-tour-tab .introjs-nextbutton {
    display: block !important;
  }
  .last-tour-tab .introjs-skipbutton {
    z-index: 1 !important;
  }
  .last-tour-tab .introjs-skipbutton {
    height: 1.52rem !important;
  }
  .headerContainer {
    display: flex !important;
  }
  .card-link {
    margin-right: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .event-mask {
    height: 2.8rem !important;
    z-index: 1 !important;
  }
  .mainbody-left {
    width: 25% !important;
  }
  .mainbody-right {
    width: 75% !important;
  }
  .last-tour-tab .introjs-skipbutton {
    width: 4.06rem !important;
  }
  .card-cont-box,
  .event-box,
  .featured-events-box {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  }
  .event-box-parent:hover .event-box,
  .featured-events-div:hover .featured-events-box {
    box-shadow: 0 4px 17px 0 rgba(0, 0, 0, 0.15);
    transition: all 0.2s ease-in;
  }
  .devJourneyComponentRight {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !important;
  }
  .lorem .blog-intro-text {
    max-height: 20rem !important;
  }
  .bloglist-descripion {
    max-height: 3.8rem !important;
  }
  .recent-blogs .section {
    width: 100% !important;
  }
  .recent-blogs .section .subsection {
    width: calc(100% - 8rem) !important;
  }
  .empty-event-div {
    height: 20rem !important;
  }
}

/*Media Queries only for the MS Edge Browser for all resolutions */
@supports (-ms-ime-align: auto) {
  .about_left {
    flex-basis: 70% !important;
  }
  .devJourneyNavbr {
    background-image: linear-gradient(
      to top,
      rgba(156, 57, 104, 0.7),
      rgb(44, 47, 76)
    );
  }
  .titleHeader {
    color: blue;
  }
  .devJourneyComponentRight .devJourneyRight {
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }
  .devJourneyComponentRight-mainbody .mainbody-left {
    overflow-y: auto !important;
  }
  .devJourneyHeader .titleHeader {
    color: black;
    margin-top: 3.5px;
  }
  .event-box-parent {
    overflow: hidden;
  }
  .journey .content {
    justify-content: space-around !important;
  }
  .lorem .blog-intro-text {
    max-height: 20rem !important;
  }
  .bloglist-descripion {
    max-height: 3.8rem !important;
  }
  .empty-event-div {
    height: 20rem !important;
  }
  ::-webkit-scrollbar {
    margin-left: 1rem;
  }
}

/* Media Queries for less height in laptops*/
@media (min-width: 768px) and (max-height: 750px) {
  .devJourneyLandingUpperHeading-small {
    padding-top: 0 !important;
  }
  .devJourneyLandingLowerHeading {
    padding: 1rem 0 1.5rem !important;
  }
}

@media (min-width: 768px) and (max-height: 580px) {
  .devJourneyMd {
    height: 20rem !important;
    min-height: 15rem !important;
  }
}

@media (min-width: 768px) and (max-height: 400px) {
  .devJourneyMd {
    height: 15rem !important;
    min-height: 12rem !important;
  }
}

@media (min-width: 1440px) and (min-height: 1500px) {
  .devJourneyRight {
    padding-bottom: 7rem !important;
  }
}

@media (min-width: 1440px) and (min-height: 1800px) {
  .devJourneyRight {
    padding-bottom: 10rem !important;
  }
}

@media (min-height: 1235px) {
  .eventList-comp {
    min-height: calc(100vh - 35rem);
  }
}

