.sticky-banner {
    position: sticky;
    top: 80px; 
    z-index: 1002; 
    background-color: #e20074; 
    color: white;
    padding: 10px 20px;
    text-align: center;
    font-family: 'Arial', sans-serif;
    border-bottom: 1px solid #fff; 
  }
  
  .sticky-banner-message {
    margin: 0;
    font-size: 14px;
    letter-spacing: 0.5px;
  }