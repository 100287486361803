
/* standard colors for site */
:root {
    --old-magenta-color: #e20074;
    --tmo-magenta: #E20074;
    --tmo-magenta-header: #ba0060;
    --dark-magenta: #BA0060;
    --very-dark-magenta: #A10053;
    --background-color: #f9f9f9;
    --font-color: #323232;
    --success-green: #078A14;
    --error-red: #E61448;
    /*--info-blue-background: ;*/

    --common-text-color: #383874;

    --old-success-green: #53CB5D;
    --old-error-red: #EC1E50;
}

.magenta-color {
    color: var(--tmo-magenta) !important;
}

.dark-magenta-color {
    color: var(--dark-magenta) !important;
}
