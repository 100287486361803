$link-hover-decoration:none;
$link-decoration:none;


// @import url("../../node_modules/bootstrap/dist/css/bootstrap.min.css");


.blue-header {
    background-color: #474a68;
}
.card {
    display: flex;
    box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.1);
    justify-content: space-between;
    border-radius: 0.25rem;
    background-color: #ffffff;
    text-align: center;
    width: 16.25rem;
    height: 20.625rem;
    transition: all 0.2s ease-in-out;
    border: 0;
}
.card:hover {
    box-shadow: 0 0.525rem 0.85rem 0 rgba(0, 0, 0, 0.1);
  }
  .card:hover .card-back {
    transform: scale(1.1);
  }
  .card:nth-child(3n) {
    margin-right: 0;
  }
  .card-img-top {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.8;
    position: relative;
    height: 9.688rem;
    background-color: #f3f4f6;
  }
  .card-text {
    // opacity: 0.5;
    font-size: 0.719rem;
    line-height: 1.5;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    color: #000000;
    display: block;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 5.6rem;
    height: 3.6rem;
  }
  .card-title {
    font-size: 1rem;
    text-align: center;
    color: #4a4a4a;
    margin-bottom: 0.8rem;
  }  
 .breadcrumb {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 !important;
    list-style: none;
    border-bottom: 0.0625rem solid #e9ecef;
    width: 94%;
    background-color: #fff !important;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    color: #6c757d;
    content: ">";
  }
  .breadcrumb-item{
    font-size: 0.9rem;
    line-height: 3.09;
    padding: 0.5rem 0;
    color: #9b9b9b;
  }
  .breadcrumb-item a {
    color: var(--tmo-magenta);
    font-size: 0.8rem;
    text-transform: capitalize;
  }
  .security-markdown .breadcrumb-item a:hover {
    text-decoration: underline !important;
  }
  .security-markdown .breadcrumb-item a:hover {
    color: var(--tmo-magenta);
    text-decoration: underline;
    transition: all 0.3s ease-in;
  }

  .footer {
    width: 100%;
    height: 4rem;
    background-color: #fff;
  }

nav.navbar {
  height: 4rem;
  transition: all 2.5s ease-in;
}

.navbar-dark .navbar-toggler {
  border: none;
}

.navbar-dark .navbar-toggler:focus {
  outline: none;
}

.nav-item {
  padding-right: 2rem;
}

.logo {
  height: 2.5rem;
  width: 12rem;
  transition: all 0.2s ease;
}

.beta {
  margin-bottom: 0.69rem;
  margin-left: -0.56rem;
}

.markdown-body a {
  color: var(--tmo-magenta) !important;
}
.markdown-body a:hover{
  color: var(--tmo-magenta) !important;
  text-decoration: underline !important;
}

#myFrame {
  /* padding: 1.875rem 2.500em !important; */
  padding: 0 !important;
}

#myFrame .header-navv {
  display: none !important;
}

#myFrame .footer {
  display: none !important;
}

.security-markdown .breadcrumbb .breadcrumb-item:last-child a:hover {
  text-decoration: none !important;
}

.devJourneyRight .devJourneyMd div ol li .custom-block .custom-block-body span img {
  margin-bottom: 0.2rem !important;
}

// .devJourneyRight .devJourneyMd div ol li .custom-block .custom-block-body p a span img {
//   margin-bottom: 0.2rem !important;
// }

.devJourneyRight .devJourneyMd div ol li .gatsby-resp-image-image {
  margin-bottom: 0.19rem !important;
}

.noborder{
  border: none !important;
}