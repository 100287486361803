.carousel.carousel-slider {
    overflow: visible;
}
.arrowPrev {
    position: absolute;
    bottom: 45%;
    left: -1rem;
    z-index: 2;
    /* cursor: pointer; */
}

.arrowNext {
    position: absolute;
    bottom: 45%;
    right: -1rem;
    /* cursor: pointer; */
}
.arrowNext img{
    width: 2.5rem;
    transform: rotate(180deg);
}
.arrowPrev img{
    width: 2.5rem;
}